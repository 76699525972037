<template>
  <iq-card body-class="p-0">
    <template v-slot:body>
      <div class="user-post-data p-3">
        <div class="d-flex flex-wrap">
          
          <div class="media-support-user-img mr-3">
            <router-link :to="{ name: 'user.profile', params: { userId: post.user.id }}">
            <b-img v-if='post.user.profile' rounded="circle" fluid :src="post.user.profile" alt="user"/>
            <b-img v-else  src="../../../../assets/images/user-icon.svg" rounded="circle" fluid alt="user"/>
            </router-link>
            
          </div>
          <div class="media-support-info mt-2">
            <router-link :to="{ name: 'user.profile', params: { userId: post.user.id }}">
            <h5 class="mb-0"><b-link href="javascript:void(0)" class="">{{post.user.name}}</b-link></h5>
            <!-- <p class="mb-0 text-secondary">{{post.time | formatDate }}</p> -->
            <p class="mb-0 text-secondary">{{post.time  }}</p>
            </router-link>
          </div>
           <div class="iq-card-header-toolbar d-flex align-items-center">
              <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0">
                <template v-slot:button-content>
                  <b-link href="#" class="text-secondary"><i class="ri-more-2-line ml-3"></i></b-link>
                </template>
                <b-dropdown-item v-if='post.isAuthPost' href="#" @click='deletePost(post.id)' ><i class="ri-user-unfollow-line mr-2"></i>Delete</b-dropdown-item>
                <b-dropdown-item v-else href="#" @click="redirectToChat(post.user.id)"><i class="ri-share-forward-line mr-2"></i>Chat</b-dropdown-item>
                <!-- <b-dropdown-item href="#"><i class="ri-file-copy-line mr-2"></i>Copy Link</b-dropdown-item> -->
              </b-dropdown>
            </div>
          </div>
          
        </div>
    </template>
    <hr class="m-0" />
    <div class="user-post">
      <p class="p-2" v-if="post.description">{{post.description}}</p>
      <p class="p-2" v-if="post.musicLink">Link : <a :href='post.musicLink' target="_blank">{{ post.musicLink }}</a></p>
      <div id="photo-grid">
        <span v-if='post.images'>
        <photo-grid :box-height="'400px'" :box-width="'100%'" :boxBorder=2>
          <img v-for="(image, index) in post.images" :src="image" :key="index"  @click="showMultiple" style="cursor:pointer" />
        </photo-grid>
        </span>
      </div>
      <div class="p-2" v-if='post.files' >
        <p v-for="(file, index) in post.files" :key="index">
          <a :href="file" target="_blank">File Link</a>
        </p>
      </div>
      <div class="p-2" v-if='post.videos && post.videos.length > 0' >
        <p v-for="(video, index) in post.videos" :key="index">
            <!-- <video  controls style="width:100%;aspect-ratio: 2 / 1;">
              <source :src="video" type="video/mp4">
              Your browser does not support the video tag.
            </video> -->
          <embed :src="video" style="width:100%;aspect-ratio: 2 / 1;"></embed>
        </p>
      </div>
    </div>
    <div class="comment-area p-3">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex align-items-center justify-content-between">
          <div class="like-block position-relative d-flex align-items-center">
            <div class="d-flex align-items-center">
              <div class="like-data">
                <div class="dropdown">
                  <!-- <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button" @click="isLiked(post.is_like,post.like_reaction)"> -->
                     <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                    <span v-if='post.is_like'>
                      <img v-if="post.like_reaction === 'Like'" :src="require('../../../../assets/images/icon/like2.png')" class="img-fluid" alt="">
                      <img v-else-if="post.like_reaction === 'Love'" :src="require('../../../../assets/images/icon/02.png')" class="img-fluid" alt="">
                      <img v-else-if="post.like_reaction === 'Happy'" :src="require('../../../../assets/images/icon/03.png')" class="img-fluid" alt="">
                      <img v-else-if="post.like_reaction === 'HaHa'" :src="require('../../../../assets/images/icon/04.png')" class="img-fluid" alt="">
                      <img v-else-if="post.like_reaction === 'Think'" :src="require('../../../../assets/images/icon/05.png')" class="img-fluid" alt="">
                      <img v-else-if="post.like_reaction === 'Sad'" :src="require('../../../../assets/images/icon/06.png')" class="img-fluid" alt="">
                      <img v-else-if="post.like_reaction === 'Lovely'" :src="require('../../../../assets/images/icon/07.png')" class="img-fluid" alt="">
                    </span>
                    <span v-else>
                       <img  :src="require('../../../../assets/images/icon/01.png')" class="img-fluid" alt="">
                    </span>
                   
                  </span>
                  <div class="dropdown-menu" style="">
                    
                    <a class="ml-2 mr-2" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" @click="isLiked(post.is_like,'Like')" title="" data-original-title="Like"><img :src="require('../../../../assets/images/icon/01.png')" class="img-fluid" alt=""></a>
                    <a class="mr-2" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" @click="isLiked(post.is_like,'Love')" title="" data-original-title="Love"><img :src="require('../../../../assets/images/icon/02.png')" class="img-fluid" alt=""></a>
                    <a class="mr-2" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" @click="isLiked(post.is_like,'Happy')" title="" data-original-title="Happy"><img :src="require('../../../../assets/images/icon/03.png')" class="img-fluid" alt=""></a>
                    <a class="mr-2" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" @click="isLiked(post.is_like,'HaHa')" title="" data-original-title="HaHa"><img :src="require('../../../../assets/images/icon/04.png')" class="img-fluid" alt=""></a>
                    <a class="mr-2" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" @click="isLiked(post.is_like,'Think')" title="" data-original-title="Think"><img :src="require('../../../../assets/images/icon/05.png')" class="img-fluid" alt=""></a>
                    <a class="mr-2" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" @click="isLiked(post.is_like,'Sad')" title="" data-original-title="Sad"><img :src="require('../../../../assets/images/icon/06.png')" class="img-fluid" alt=""></a>
                    <a class="mr-2" href="javascript:void(0)" data-toggle="tooltip" data-placement="top" @click="isLiked(post.is_like,'Lovely')" title="" data-original-title="Lovely"><img :src="require('../../../../assets/images/icon/07.png')" class="img-fluid" alt=""></a>
                  </div>
                </div>
              </div>
              <div class="total-like-block ml-2 mr-3">
                <div class="dropdown">
                <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
                {{post.likes}} Likes
                </span>
                  <!-- <div class="dropdown-menu">
                    <a class="dropdown-item" href="javascript:void(0)">Max Emum</a>
                    <a class="dropdown-item" href="javascript:void(0)">Bill Yerds</a>
                    <a class="dropdown-item" href="javascript:void(0)">Hap E. Birthday</a>
                    <a class="dropdown-item" href="javascript:void(0)">Tara Misu</a>
                    <a class="dropdown-item" href="javascript:void(0)">Midge Itz</a>
                    <a class="dropdown-item" href="javascript:void(0)">Sal Vidge</a>
                    <a class="dropdown-item" href="javascript:void(0)">Other</a>
                  </div> -->
                </div>
              </div>
            </div>
            <div class="total-comment-block">
              <div class="dropdown">
               <span class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" role="button">
               {{post.comments.length}} Comment
               </span>
                <!-- <div class="dropdown-menu">
                  <a class="dropdown-item" href="javascript:void(0)">Max Emum</a>
                  <a class="dropdown-item" href="javascript:void(0)">Bill Yerds</a>
                  <a class="dropdown-item" href="javascript:void(0)">Hap E. Birthday</a>
                  <a class="dropdown-item" href="javascript:void(0)">Tara Misu</a>
                  <a class="dropdown-item" href="javascript:void(0)">Midge Itz</a>
                  <a class="dropdown-item" href="javascript:void(0)">Sal Vidge</a>
                  <a class="dropdown-item" href="javascript:void(0)">Other</a>
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="share-block d-flex align-items-center feather-icon mr-3">
          <a href="javascript:void(0)"><i class="ri-share-line"></i>
            <span class="ml-1">99 Share</span></a>
        </div>
      </div>
      <hr>
      <ul class="post-comments p-0 m-0">
        <li class="mb-2" v-for="(postComment, postCommentIndex) in post.comments" :key="postComment.id">
          <div class="d-flex flex-wrap">
            <div class="user-img">
              <b-img v-if='postComment.image' :src="postComment.image" alt="userimg" class="avatar-35"  rounded="circle" fluid />
              <b-img v-else  src="../../../../assets/images/user-icon.svg" rounded="circle" class="avatar-35" fluid alt="userimg"/>
            </div>
            <div class="comment-data-block ml-3">
              <h6>{{postComment.user.name}}</h6>
              <p class="mb-0">{{postComment.user.msg}}</p>
              <div class="d-flex flex-wrap align-items-center comment-activity">
                <b-link @click="isLikedComment(postCommentIndex, !postComment.is_commentLike,postComment.id)">
                  <span v-if="!postComment.is_commentLike" class="text-primary">like</span>
                  <span class="text-secondary" v-else>unlike</span>
                </b-link>
                <b-link v-if="postComment.isAuthComment" @click='deleteComment(postComment.id)' href="javascript:void(0);">Delete</b-link>
                <!-- <b-link href="javascript:void(0);">reply</b-link> -->
                <!-- <span>{{postComment.user.time | formatDate}}</span> -->
                 <span>{{postComment.user.time }}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <b-form class="comment-text d-flex align-items-center mt-3" action="javascript:void(0);">
        <b-form-input type="text" @keyup.enter="saveComment(commentMessage)" v-model="commentMessage" class="rounded" placeholder="Lovely!" />
        <div class="comment-attagement d-flex">
          <b-link href="javascript:void(0);"><i class="ri-send-plane-line mr-2" @click="saveComment(commentMessage)"></i></b-link>
        </div>
      </b-form>
    </div>
    <vue-easy-lightbox
      escDisabled
      moveDisabled
      :visible="visible"
      :imgs="post.images"
      :index="index"
      @hide="handleHide"
    ></vue-easy-lightbox>
</iq-card>
</template>
<script>
import axios from '../../../../components/axios'
import VueEasyLightbox from 'vue-easy-lightbox'
import Embed from 'v-video-embed'

export default {
  name: 'SocialPost',
  components: { VueEasyLightbox,Embed},
  props: [
    'post',
    'deletePostApi',
    'deleteCommentApi',
  ],
  data () {
    return {
      commentMessage: '',
      is_like:'',
      is_commentLike:'',
      messageData:{
        to_user_id:'',
        message:""
      },
      visible: false,
      index: 0 ,// default: 0
      playerOptions: {
          // videojs options
        muted: true,
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [{
          type: "video/mp4",
          src: "http://localhost:8000/storage/posts/VJ_aX8Wd2NvmtFwN97.mp4"
        }],
        // poster: "/static/images/author.jpg",
      }
    }
  },
  mounted() {
axios.defaults.headers["Authorization"] = 
    "Bearer " + localStorage.getItem("token");
  },
 
  methods: {
  
      showMultiple() {
       
        // allow mixing

        this.index = 1 // index of imgList
        this.show()
      },
      show() {
        this.visible = true
      },
      handleHide() {
        this.visible = false
      },
    deletePost(postId){
       if(confirm('Are you sure you want to delete this post? ')){
         this.deletePostApi(postId);
       }
    
    },
    deleteComment(commentId){

       if(confirm('Are you sure to delete this comment')){
         this.deleteCommentApi(commentId)
       }
       
    },
    redirectToChat(userId){
      var parent = this
      parent.messageData.to_user_id = userId
      parent.messageData.message = 'rwfd'
      var config = {
        method: "post",
        url: 'sendmessage', 
        data: parent.messageData
      };

       axios(config)
      .then(function(response) {

        parent.$router.push('/app/chat')
      })
      .catch(function(error) {
        console.log(error);
      });
    },
    async isLiked (postLike,reaction) {
      if(postLike){
        if(this.post.like_reaction === reaction){
          this.post.likes -= 1
          this.is_like = 0;
          this.post.like_reaction = 'Dislike';
          reaction = 'Dislike';
          this.post.is_like = 0;
        }else{
          this.post.like_reaction = reaction;
          this.is_like = 1;
          this.post.is_like = 1;
        }

      }else{
        this.post.is_like = !postLike
        postLike = !postLike

        if(reaction !== 'Dislike'){
          reaction = reaction
        }else{
          reaction = 'Like';
        }
        this.post.like_reaction = reaction;
        if (postLike) {
          this.post.likes += 1
          this.is_like = 1;
        } else {
          this.post.likes -= 1
          this.is_like = 0;
        }
      }


      

      const config = {
          method: "post",
          url: "like",
          data:{
            post_id:this.post.id,
            is_like:this.is_like,
            like_for:1,
            reaction:reaction
          },
      };

      await axios(config).then(res => {
      }).catch(err => {
          console.log(err.response);
      })
    },
    isFollowed (follow) {
      this.post.is_follow = follow
    },
    async isLikedComment (index, like,comment_id) {
      if(like){
        this.is_commentLike = 1;
      }else{
        this.is_commentLike = 0;
      }
      const config = {
          method: "post",
          url: "like",
          data:{
            post_id:this.post.id,
            is_like:this.is_commentLike,
            comment_id:comment_id,
            like_for:0
          },
      };


      await axios(config).then(res => {
        this.post.comments[index].is_commentLike = like
      }).catch(err => {
          console.log(err.response);
      })
    },
    async saveComment (postComment) {
      // this.post.comments.push({
      //   image: require('../../../../assets/images/user/user-04.jpg'),
      //   user: { name: 'Sandy S', msg: postComment, time: new Date() },
      //   is_commentLike: false
      // })

      const config = {
          method: "post",
          url: "createcomment",
          data:{
            post_id:this.post.id,
            comment:postComment,
          },
      };

      await axios(config).then(res => {
        this.post.comments.push({
        id: res.data.comment.id,
        image: res.data.comment.image,
        user: res.data.comment.user,
        is_commentLike: res.data.comment.is_commentLike,
        isAuthComment: res.data.comment.isAuthComment,

      })
      }).catch(err => {
          console.log(err.response);
      })
      this.commentMessage = ''
    }
  }
}
</script>

<style>
  .dropdown-item{
    color: #212529 !important;
  }
  .dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover{
    background: transparent;
    color: var(--iq-primary) !important;
  }
</style>
